import { Injectable } from '@angular/core';
import { Observable, of} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: "root"
})

export class UploadService {
  constructor(private http: HttpClient) {}

  // Multipart file upload
  uploadFile(formData): Observable<any> {
   	const httpOptions = {
      headers: new HttpHeaders({
        'token':localStorage.getItem(environment.authTokenKey),
        'contentType': 'multipart/form-data'
      })
    };
    return this.http.post(API_URL+'/fileUpload', formData);
  }

  // Get file url using upload id
  getUploadedFile(fileUploadId: string): Observable<any> {
    return this.http.get(API_URL+'/fileUpload/presignurl?fileUploadId='+fileUploadId);
  }

}
