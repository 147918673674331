import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
// CRUD
import { QueryParamsModel, QueryResultsModel } from "../../_base/crud";

import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';

const API_URL = environment.apiUrl;

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(private angularFireMessaging: AngularFireMessaging,
              private http: HttpClient) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        let data = {
          regToken: token
        }
        this.updateFcmToken(data);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
    (payload) => {
      this.currentMessage.next(payload);
    });
  }

  // Forgot Password API
  updateFcmToken(data: any) {
    this.http.post<any>(API_URL+'/auth/fcm/token', data).subscribe(res=>{
      console.log(res);
    });
  }

  // Get all notifications
  getAllNotifications(queryParams: QueryParamsModel): Observable<any> {
    return this.http
      .get<any>(
        API_URL +
          '/auth/fcm/notifications?' +
          'pageSize=' +
          queryParams.pageSize +
          '&pageNumber=' +
          queryParams.pageNumber +
          '&searchText=' +
          queryParams.searchText +
          queryParams.filter
      )
      .pipe(
        mergeMap(res => {
          //console.log(res);
          if (res.response.code === 200) {
            const result = {
              items: res.data.list,
              totalCount: res.data.totalCount
            };
            return of(result);
          } else {
            const result = {
              items: [],
              totalCount: 0
            };
            return of(result);
          }
        })
      );
  }

  // Update read Status
  updateMessageReadStatus(data: any) {
    return this.http.put<any>(API_URL+'/auth/fcm/read', data);
  }
}
