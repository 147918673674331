export class PageConfig {
  public defaults: any = {
    // Dashboard
    'dashboard': {
      page: {
        title: 'Dashboard',
        breadcrumb: [
          {
            title: 'Statics and Analytics',
          }
        ],
      }
    },
    // Management
    'users': {
      'members': {
        page: {
          title: 'User Management',
          breadcrumb: [
            {
              title: 'Users',
              page: '/users'
            },
            {
              title: 'List'
            }
          ],
        }
      },
      'roles': {
        page: {
          title: 'Role Management',
          breadcrumb: [
            {
              title: 'Users',
              page: '/users'
            },
            {
              title: 'Roles'
            }
          ],
        }
      },
      'work-flow': {
        page: {
          title: 'Work Flow Editor',
          breadcrumb: [
            {
              title: 'Users',
              page: '/users'
            },
            {
              title: 'Work Flow'
            }
          ],
        }
      }
    },
    'devices': {
      page: {
        title: 'Device Management',
        breadcrumb: [
          {
            title: 'Devices',
            page: '/devices',
          },
          {
            title: 'List',
          }
        ],
      }
    },
    'assets': {
      page: {
        title: 'Assets Management',
        breadcrumb: [
          {
            title: 'Assets',
            page: '/assets',
          },
          {
            title: 'List',
          }
        ],
      }
    },
    'projects': {
      'projects': {
        page: {
          title: 'Project Management',
          breadcrumb: [
            {
              title: 'Projects',
              page: '/projects',
            },
            {
              title: 'List',
            }
          ],
        }
      },
      'locations': {
        page: {
          title: 'Location Management',
          breadcrumb: [
            {
              title: 'Projects',
              page: '/projects',
            },
            {
              title: 'Locations',
            }
          ],
        }
      }
    },
    // Reports
    'reports': {
      'reports': {
        page: {
          title: 'Report',
          breadcrumb: [
            {
              title: 'Report',
              page: '/reports',
            },
            {
              title: 'List',
            }
          ],
        }
      }
    },
    // Logs and reports
    'logs': {
      page: {
        title: 'System Logs',
        breadcrumb: [
          {
            title: 'Logs',
            page: '/logs'
          },
          {
            title: 'System Logs'
          }
        ],
      }
    },
    // Other
    'profile': {
      'profile-info': {
        page: {
          title: 'Profile Info',
          breadcrumb: [
            {
              title: 'Profile',
              page: '/profile',
            },
            {
              title: 'Profile info',
            }
          ],
        }
      },
      'change-password': {
        page: {
          title: 'Change Password',
          breadcrumb: [
            {
              title: 'Profile',
              page: '/profile',
            },
            {
              title: 'Change Password',
            }
          ],
        }
      }
    },
    'notifications': {
      page: {
        title: 'Notifications',
        breadcrumb: [
          {
            title: 'Notifications',
          }
        ],
      }
    },
    error: {
      404: {
        page: {
          title: '404 Not Found',
          subheader: false
        }
      },
      403: {
        page: {
          title: '403 Access Forbidden',
          subheader: false
        }
      }
    }
  };

  public get configs(): any {
    return this.defaults;
  }
}
