import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

  constructor() { }

  public exportAsExcelFileWithMultiSheet(excelFileName: string, listArray: any[], excelSheetName: string[], columns: any[] = []): void {
    let excelSheets: any = {};
    for (let i = 0; i < excelSheetName.length; i++) {
      let data: any[] = [];
      if (columns.length > 0 && columns[i].length > 0) {
        for (let j = 0; j < listArray[i].length; j++) {
          let newData: any = {};
          columns[i].forEach(item => {
            newData[item[0]] = listArray[i][j][item[1]];
          });
          data.push(newData);
        }
      } else {
        data = listArray[i];
      }
      let myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
      excelSheets[excelSheetName[i]] = myworksheet;
    }
    const myworkbook: XLSX.WorkBook = { Sheets: excelSheets, SheetNames: excelSheetName };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelFile(json: any[], excelFileName: string, columns: any[] = []): void {
    let data: any[] = [];
    if (columns.length > 0) {
      for (let index = 0; index < json.length; index++) {
        let newData: any = {};
        columns.forEach(item => {
          newData[item[0]] = json[index][item[1]];
        });
        data.push(newData);
      }
    } else {
      data = json;
    }
    console.log(data);
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_exported' + EXCEL_EXTENSION);
  }

}
