<div class="bg-white rounded10 shadow-lg hide-overflow-limit" style="position: relative;">
  <mat-progress-bar class="progress-bar-top" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <div class="content-top-agile p-20 pb-0">
    <h2 class="text-primary">Reset Password</h2>
    <p class="mb-0">Set a new password for your account</p>
  </div>
  <div class="p-40">
    <form [formGroup]="resetForm">
      <app-alert></app-alert>
      <div class="form-group">
        <div class="input-group mt-3">
          <span class="input-group-text bg-transparent"
            ><i class="ti-lock"></i
          ></span>
          <input class="form-control ps-15 bg-transparent"  type="password" formControlName="password" placeholder="Enter New Password" />
        </div>
        <div *ngIf="isControlHasError('password')" class="form-control-feedback text-danger">
          <small *ngIf="isControlHasError('password','required')">
            Password is required
          </small>
          <small *ngIf="isControlHasError('password','pattern')">
            Password must be at least 8 characters in length with a combination of uppercase letters, lowercase letters, number and special characters.
          </small>
        </div>
      </div>
      <div class="form-group">
        <div class="input-group mt-3">
          <span class="input-group-text bg-transparent"
            ><i class="ti-lock"></i
          ></span>
          <input class="form-control ps-15 bg-transparent" type="password" formControlName="confirmPassword" placeholder="Confirm Password"/>
        </div>
        <div *ngIf="isControlHasError('confirmPassword')" class="form-control-feedback text-danger">
          <small *ngIf="isControlHasError('confirmPassword','required')">
            Confirm password is required
          </small>
          <small *ngIf="resetForm.get('confirmPassword').errors && resetForm.get('confirmPassword').errors.ConfirmPassword">
            Confirm Password must match with password.
          </small>
        </div>
      </div>
      <div class="row">
        <!-- /.col -->
        <div class="col-12 text-center">
          <button type="submit" (click)="onSubmit()" class="btn btn-primary margin-top-10">
            CONFIRM
          </button>
        </div>
        <!-- /.col -->
      </div>
    </form>
  </div>
</div>
