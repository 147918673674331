<div class="theme-primary">
  <div class="box-header with-border hide-overflow-limit" style="border: none !important;padding-bottom:0;">
    <mat-progress-bar class="progress-bar-top" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  </div>
  <!-- /.box-header -->
  <div class="box-body">
    <div class="drop-area">
      <div class="upload-text" *ngIf="!tempUrl">
        <span>Drag and drop or click to upload</span>
      </div>
      <input type="file" title="" id="myFileInputPopup" accept="image/*" (change)="handleUploadEventDrop($event)" (click)="removeSelected($event)"/>
      <div class="image-box">
        <img src="{{tempUrl}}">
        <div class="remove-btn" (click)="removeImage()" *ngIf="tempUrl" mat-raised-button matTooltip="Remove Image">
          <i class="fa fa-trash text-danger font-size-13"></i>
        </div>
        <div class="update-btn" onclick="document.getElementById('myFileInputPopup').click()" *ngIf="tempUrl" mat-raised-button matTooltip="Change Image">
          <span><i class="fa fa-pencil text-success"></i></span>
        </div>
      </div>
    </div>
    <p>Maximum upload size is 10MB</p>
  </div>
  <!-- /.box-body -->
  <div class="box-footer text-end" style="border: none !important;padding-top:0;">
    <button type="button" class="btn btn-warning me-3" (click)="closeDialog()">
      <i class="ti-close"></i> Cancel
    </button>
    <button type="submit" class="btn btn-primary" (click)="onSubmit()" [disabled]="loading">
      <i class="ti-check"></i> Upload
    </button>
  </div>
</div>
