<div class="bg-white rounded10 shadow-lg hide-overflow-limit" style="position: relative;">
  <mat-progress-bar class="progress-bar-top" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <div class="content-top-agile p-20 pb-0">
    <h3 class="mb-0 text-primary">Recover Account Password</h3>
  </div>
  <div class="p-40">
    <form [formGroup]="forgotForm">
      <app-alert></app-alert>
      <div class="form-group">
        <div class="input-group mt-1">
          <span class="input-group-text bg-transparent"><i class="ti-email"></i></span>
          <input type="email" class="form-control ps-15 bg-transparent" formControlName="email" placeholder="Enter Your Email">
        </div>
        <div *ngIf="isControlHasError('email')" class="form-control-feedback text-danger">
          <small *ngIf="isControlHasError('email','required')">
            Email is required
          </small>
          <small *ngIf="isControlHasError('email','pattern')">
            Email must be a valid email address
          </small>
        </div>
      </div>
        <div class="row mt-3">
        <div class="col-12 text-center">
          <button type="submit" (click)="onSubmit()" class="btn btn-primary margin-top-10">RESET</button>
        </div>
        <!-- /.col -->
        </div>
    </form>
    <div class="text-center">
      <p class="mt-15 mb-0">
        Already have an account? Please
        <a href="javascript:void(0)" routerLink="/auth/login" class="text-warning">Sign In</a> here.
      </p>
    </div>
  </div>
</div>
