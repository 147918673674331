<div class="user-profile my-15 px-20 py-10 b-1 rounded10 mx-15" *ngIf="user$ | async as _user">
  <div class="d-flex align-items-center justify-content-between">
    <div class="image d-flex align-items-center">
      <img
        src="{{_user?.profileImageId?.imageUrl ? _user?.profileImageId?.imageUrl : '/assets/images/icons/profile.png'}}"
        class="rounded10 me-10"
        alt="User Image"
      />
      <div>
        <h4 class="mb-0 fw-600">{{ _user?.name ? 'Hi '+(_user?.name | titlecase) : 'Hi Nobie'}}</h4>
        <p class="mb-0">{{ _user?.email }}</p>
      </div>
    </div>
    <div class="info">
      <a
        class="dropdown-toggle p-15 d-grid"
        data-bs-toggle="dropdown"
        href="#"
      ></a>
      <div class="dropdown-menu dropdown-menu-end">
        <a class="dropdown-item" routerLink="/profile" href="javascript:void(0)"
          ><i class="ti-user"></i> My Profile</a
        >
        <a class="dropdown-item" (click)="lockScreen()" href="javascript:void(0)"
          ><i class="ti-link"></i> Lock Screen</a
        >
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" (click)="logout()" href="javascript:void(0)"
          ><i class="ti-lock"></i> Logout</a
        >
      </div>
    </div>
  </div>
</div>
