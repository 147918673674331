<div class="theme-primary">
  <div class="box-header with-border hide-overflow-limit" style="border: none !important;padding-bottom:0;">
    <mat-progress-bar class="progress-bar-top" *ngIf="viewLoading" mode="indeterminate"></mat-progress-bar>
  </div>
  <!-- /.box-header -->
  <div class="box-body">
    <div class="animated bounce">
      <div class="icon-box-c icon-{{data.type}}">
        <i class="fa {{getIcon(data.type)}}"></i>
      </div>
    </div>
    <h5 class="modal-title font-size-16 mt-2 mb-1 text-dark text-center">{{data.title}}</h5>
    <p class="font-size-4 text-dark text-center">{{data.description}}</p>
  </div>
  <!-- /.box-body -->
  <div class="box-footer text-center" style="border: none !important;padding-top:0;">
    <button type="button" class="btn btn-warning m-2" (click)="onCancel()">
      <i class="ti-close"></i> {{data.cancelButton}}
    </button>
    <button *ngIf="data.confirmButton" type="submit" class="btn btn-primary m-2" (click)="onConfirm()" [disabled]="viewLoading">
      <i class="ti-check"></i> {{data.confirmButton}}
    </button>
  </div>
</div>

