// Angular
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// Material
import { MatDialog } from '@angular/material/dialog';
// Toast
import { ToastrService } from 'ngx-toastr';
// Partials for CRUD
import {
	ConfirmationEntityComponent
} from '../../../../views/partials/crud/';

export enum MessageType {
	success,
	danger,
	info,
	warning
}

@Injectable({
	providedIn: 'root'
})
export class LayoutUtilsService {

	onAlertChanged$: BehaviorSubject<any>;

	constructor(private dialog: MatDialog,
				private toastr: ToastrService) {
		this.onAlertChanged$ = new BehaviorSubject(null);
	}

	// Show alert box
	showAlert(
		_type: MessageType = MessageType.danger,
		_message: string = '',
		_duration: number = 5000,
		_showCloseButton: boolean = false
	) {
		const notice = {
			type: _type,
			message: _message,
			duration: _duration,
			showCloseButton: _showCloseButton,
		};
		this.onAlertChanged$.next(notice);
	}

	// Show notification box
	showActionNotification(
		_type: MessageType,
		_title: string,
		_message: string,
		_duration: number = 3000
	) {
		switch (_type) {
			case 0:
				this.toastr.success(_message, _title, {
					timeOut: _duration,
				});
				break;
			case 1:
				this.toastr.error(_message, _title, {
					timeOut: _duration,
				});
				break;
			case 2:
				this.toastr.info(_message, _title, {
					timeOut: _duration,
				});
				break;
			case 3:
				this.toastr.warning(_message, _title, {
					timeOut: _duration,
				});
				break;
			default:
				this.toastr.success(_message, _title, {
					timeOut: _duration,
				});
				break;
		}
	}

  confirmationPopup(type: string = 'info', title: string = '', description: string = '', confirmButton: string = 'Confirm', cancelButton: string = 'Cancel') {
    console.log(description);
		return this.dialog.open(ConfirmationEntityComponent, {
      data: { type, title, description, confirmButton, cancelButton },
			width: '440px'
		});
	}
}
