// Angular
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
// Components
import { BaseComponent } from "./views/theme/base/base.component";
import { ErrorPageComponent } from "./views/partials/general/error-page/error-page.component";
// Auth
import { AuthGuard } from "./core/auth";

const routes: Routes = [
  // Authentication
  {
    path: "auth",
    loadChildren: () =>
      import("./views/pages/auth/auth.module").then(m => m.AuthModule)
  },
  // Auth Guard Rotes
  {
    path: "",
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      // Dashboards
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/pages/dashboard/dashboard.module").then(
            m => m.DashboardModule
          )
      },
      // Management Section
      {
        path: "users",
        loadChildren: () =>
          import("./views/pages/users/users.module").then(
            m => m.UsersModule
          )
      },
      {
        path: "devices",
        loadChildren: () =>
          import("./views/pages/devices/devices.module").then(
            m => m.DevicesModule
          )
      },
      {
        path: "assets",
        loadChildren: () =>
          import("./views/pages/assets/assets.module").then(
            m => m.AssetsModule
          )
      },
      {
        path: "projects",
        loadChildren: () =>
          import("./views/pages/projects/projects.module").then(
            m => m.ProjectsModule
          )
      },
      // Reports Section
      {
        path: "reports",
        loadChildren: () =>
          import("./views/pages/reports/reports.module").then(
            m => m.ReportsModule
          )
      },
      // Logs
      {
        path: "logs",
        loadChildren: () =>
          import("./views/pages/logs/logs.module").then(
            m => m.LogsModule
          )
      },
      // General Pages
      {
        path: "profile",
        loadChildren: () =>
          import("./views/pages/profile/profile.module").then(
            m => m.ProfileModule
          )
      },
      {
        path: "notifications",
        loadChildren: () =>
          import("./views/pages/notifications/notifications.module").then(
            m => m.NotificationsModule
          )
      },
      // Extra Routes With Auth Guard
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full"
      },
      {
        path: "**",
        redirectTo: "error/404",
        pathMatch: "full"
      }
    ]
  },
  // Extra Routes Without Auth Guard
  {
    path: "error/:code",
    component: ErrorPageComponent
  },
  {
    path: "**",
    redirectTo: "error/404",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
