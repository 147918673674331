import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// NgBootstrap
import { NgbDropdownModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
// Loading bar
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { ClickOutsideModule } from 'ng-click-outside';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SimplebarAngularModule } from 'simplebar-angular';
// Auth
import {
  ModuleEffects,
  modulesReducer,
  RoleEffects,
  rolesReducer } from '../../core/auth';

// Partials
import { PartialsModule } from '../partials/partials.module';

import { BaseComponent } from './base/base.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    BaseComponent,
    SidebarComponent,
    TopbarComponent,
    FooterComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxPermissionsModule.forChild(),
    StoreModule.forFeature('roles', rolesReducer),
    EffectsModule.forFeature([RoleEffects,]),
    NgbDropdownModule,
    NgbProgressbarModule,
    LoadingBarModule,
    ClickOutsideModule,
    PerfectScrollbarModule,
    PartialsModule,
    SimplebarAngularModule
  ],
  exports: [
    BaseComponent,
    SidebarComponent,
    TopbarComponent,
    FooterComponent,
  ],
})

export class ThemeModule {}
