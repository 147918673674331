// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// State
import { ModulesState } from '../_reducers/module.reducers';
import * as fromModules from '../_reducers/module.reducers';

export const selectModulesState = createFeatureSelector<ModulesState>('modules');

export const selectModuleById = (moduleId: number) => createSelector(
    selectModulesState,
    ps => ps.entities[moduleId]
);

export const selectAllModules = createSelector(
    selectModulesState,
    fromModules.selectAll
);

export const selectAllModulesIds = createSelector(
    selectModulesState,
    fromModules.selectIds
);

export const allModulesLoaded = createSelector(
    selectModulesState,
    ps  => ps._isAllModulesLoaded
);
