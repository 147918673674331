// NGRX
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector } from '@ngrx/store';
// Actions
import { ModuleActionTypes, ModuleActions } from '../_actions/module.actions';
// Models
import { Module } from '../_models/module.model';

export interface ModulesState extends EntityState<Module> {
    _isAllModulesLoaded: boolean;
}

export const adapter: EntityAdapter<Module> = createEntityAdapter({
  selectId: (module: Module) => module._id,
});

export const initialModulesState: ModulesState = adapter.getInitialState({
    _isAllModulesLoaded: false
});

export function modulesReducer(state = initialModulesState, action: ModuleActions): ModulesState {
    switch  (action.type) {
        case ModuleActionTypes.AllModulesRequested:
            return {...state,
                _isAllModulesLoaded: false
        };
        case ModuleActionTypes.AllModulesLoaded:
            return adapter.addAll(action.payload.modules, {
                ...state,
                _isAllModulesLoaded: true
            });
        default:
            return state;
    }
}

export const getRoleState = createFeatureSelector<ModulesState>('modules');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
