import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, ViewChild, Input, OnChanges, OnDestroy } from '@angular/core';
import MetisMenu from 'metismenujs/dist/metismenujs';
import { Router, NavigationEnd } from '@angular/router';
// RxJs
import { filter } from 'rxjs/operators';
// Object Path
import * as objectPath from 'object-path';
// Layout
import { LayoutConfigService, MenuConfigService } from '../../../core/_base/layout';
import { Subscription } from 'rxjs';

declare const jQuery: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * Sidebar component
 */
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('componentRef') scrollRef;
  @ViewChild('sideMenu') sideMenu: ElementRef;
  @Input() isCondensed = false;

  // set the currenr year
  year: number = new Date().getFullYear();

  currentRouteUrl = '';
  menu: any;
  configData = {
    suppressScrollX: true,
    wheelSpeed: 0.3
  };

  menuList: any[] = [];

  menuItemLoaded: boolean = false;

  // Private properties
  private subscriptions: Subscription[] = [];

  constructor(private layoutConfigService: LayoutConfigService,
              public menuConfigService: MenuConfigService,
              private router: Router,
              private cdr: ChangeDetectorRef) {

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRouteUrl = this.router.url.split(/[?#]/)[0];
        if (this.sideMenu) {
          setTimeout(() => {
            if (this.menuItemLoaded) {
              this._activateMenuDropdown();
              //this._scrollElement();
            }
          }, 300);
        }
      }
    });
  }

  ngOnInit() {
    document.body.setAttribute('data-sidebar', 'dark');

    this.currentRouteUrl = this.router.url.split(/[?#]/)[0];

    setTimeout(() => {
      //this.menu = new MetisMenu(this.sideMenu.nativeElement);
      // if (this.menuItemLoaded) {
      //   this._activateMenuDropdown();
      //   //this._scrollElement();
      // }
    }, 2000);

    this.subscriptions.push(this.menuConfigService.menuList$.subscribe(res=>{
      this.menuItemLoaded = true;
      this._activateMenuDropdown();
    }));

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  ngAfterViewInit() {
    //this.menu = new MetisMenu(this.sideMenu.nativeElement);
    // if (this.menuItemLoaded) {
    //   this._activateMenuDropdown();
    //   //this._scrollElement();
    // }
  }

  ngOnChanges() {
    if (!this.isCondensed && this.sideMenu || this.isCondensed) {
      this.menu = new MetisMenu(this.sideMenu.nativeElement);
    } else if (this.menu) {
      this.menu.dispose();
    }
  }

  _scrollElement() {
    setTimeout(() => {
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0]['offsetTop'];
        if (currentPosition > 500)
          if (this.scrollRef.SimpleBar.getScrollElement() !== null)
            this.scrollRef.SimpleBar.getScrollElement().scrollTop =
              currentPosition + 300;
      }
    }, 300);
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    +function ($) {
      'use strict'

      var DataKey = 'Masteradmin.tree'

      var Default = {
        animationSpeed: 500,
        accordion: true,
        followLink: false,
        trigger: '.treeview a'
      }

      var Selector = {
        tree: '.tree',
        treeview: '.treeview',
        treeviewMenu: '.treeview-menu',
        open: '.menu-open, .active',
        li: 'li',
        data: '[data-widget="tree"]',
        active: '.active'
      }

      var ClassName = {
        open: 'menu-open',
        tree: 'tree'
      }

      var Event = {
        collapsed: 'collapsed.tree',
        expanded: 'expanded.tree'
      }

      // Tree Class Definition
      var Tree = function (element, options) {
        this.element = element
        this.options = options

        $(this.element).addClass(ClassName.tree)

        $(Selector.treeview + Selector.active, this.element).addClass(ClassName.open)

        this._setUpListeners()
      }

      Tree.prototype.toggle = function (link, event) {
        var treeviewMenu = link.next(Selector.treeviewMenu)
        var parentLi = link.parent()
        var isOpen = parentLi.hasClass(ClassName.open)

        if (!parentLi.is(Selector.treeview)) {
          return
        }

        if (!this.options.followLink || link.attr('href') == '#') {
          event.preventDefault()
        }

        if (isOpen) {
          this.collapse(treeviewMenu, parentLi)
        } else {
          this.expand(treeviewMenu, parentLi)
        }
      }

      Tree.prototype.expand = function (tree, parent) {
        var expandedEvent = $.Event(Event.expanded)

        if (this.options.accordion) {
          var openMenuLi = parent.siblings(Selector.open)
          var openTree = openMenuLi.children(Selector.treeviewMenu)
          this.collapse(openTree, openMenuLi)
        }

        parent.addClass(ClassName.open)
        tree.slideDown(this.options.animationSpeed, function () {
          $(this.element).trigger(expandedEvent)
        }.bind(this))
      }

      Tree.prototype.collapse = function (tree, parentLi) {
        var collapsedEvent = $.Event(Event.collapsed)

        tree.find(Selector.open).removeClass(ClassName.open)
        parentLi.removeClass(ClassName.open)
        tree.slideUp(this.options.animationSpeed, function () {
          tree.find(Selector.open + ' > ' + Selector.treeview).slideUp()
          $(this.element).trigger(collapsedEvent)
        }.bind(this))
      }

      // Private

      Tree.prototype._setUpListeners = function () {
        var that = this

        $(this.element).on('click', this.options.trigger, function (event) {
          that.toggle($(this), event)
        })
      }

      // Plugin Definition
      function Plugin(option) {
        return this.each(function () {
          var $this = $(this)
          var data = $this.data(DataKey)

          if (!data) {
            var options = $.extend({}, Default, $this.data(), typeof option === 'object' && option)
            $this.data(DataKey, new Tree($this, options))
          }
        })
      }

      var old = $.fn.tree

      $.fn.tree = Plugin
      $.fn.tree.Constructor = Tree

      // No Conflict Mode
      $.fn.tree.noConflict = function () {
        $.fn.tree = old
        return this
      }

      // Tree Data API
      $(Selector.data).each(function () {
        Plugin.call($(this))
      })

    }(jQuery)
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: any) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
}
