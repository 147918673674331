<div class="modal modal-right fade" id="quick_actions_toggle" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content slim-scroll">
      <div class="modal-body bg-white p-30">
        <div class="d-flex align-items-center justify-content-between pb-30">
          <h4 class="m-0">
            Quick Actions<br />
            <small class="badge fs-12 badge-primary mt-10"
              >23 tasks pending</small
            >
          </h4>
          <a
            href="#"
            class="btn btn-icon btn-danger-light btn-sm no-shadow"
            data-bs-dismiss="modal"
          >
            <span class="fa fa-close"></span>
          </a>
        </div>
        <div class="row">
          <div class="col-6">
            <a
              class="
                waves-effect waves-light
                btn btn-app btn btn-primary-light btn-flat
                mx-0
                mb-20
                no-shadow
                py-35
                h-auto
                d-block
              "
              href="accounting.html"
            >
              <i class="icon-Euro fs-36"
                ><span class="path1"></span><span class="path2"></span
              ></i>
              <span class="fs-16">Accounting</span>
            </a>
          </div>
          <div class="col-6">
            <a
              class="
                waves-effect waves-light
                btn btn-app btn btn-primary-light btn-flat
                mx-0
                mb-20
                no-shadow
                py-35
                h-auto
                d-block
              "
              href="contact_userlist_grid.html"
            >
              <i class="icon-Mail-attachment fs-36"
                ><span class="path1"></span><span class="path2"></span
              ></i>
              <span class="fs-16">Members</span>
            </a>
          </div>
          <div class="col-6">
            <a
              class="
                waves-effect waves-light
                btn btn-app btn btn-primary-light btn-flat
                mx-0
                mb-20
                no-shadow
                py-35
                h-auto
                d-block
              "
              href="projects.html"
            >
              <i class="icon-Box2 fs-36"
                ><span class="path1"></span><span class="path2"></span
              ></i>
              <span class="fs-16">Projects</span>
            </a>
          </div>
          <div class="col-6">
            <a
              class="
                waves-effect waves-light
                btn btn-app btn btn-primary-light btn-flat
                mx-0
                mb-20
                no-shadow
                py-35
                h-auto
                d-block
              "
              href="contact_userlist.html"
            >
              <i class="icon-Group fs-36"
                ><span class="path1"></span><span class="path2"></span
              ></i>
              <span class="fs-16">Customers</span>
            </a>
          </div>
          <div class="col-6">
            <a
              class="
                waves-effect waves-light
                btn btn-app btn btn-primary-light btn-flat
                mx-0
                mb-20
                no-shadow
                py-35
                h-auto
                d-block
              "
              href="mailbox.html"
            >
              <i class="icon-Chart-bar fs-36"
                ><span class="path1"></span><span class="path2"></span
                ><span class="path3"></span><span class="path4"></span
              ></i>
              <span class="fs-16">Email</span>
            </a>
          </div>
          <div class="col-6">
            <a
              class="
                waves-effect waves-light
                btn btn-app btn btn-primary-light btn-flat
                mx-0
                mb-20
                no-shadow
                py-35
                h-auto
                d-block
              "
              href="setting.html"
            >
              <i class="icon-Color-profile fs-36"
                ><span class="path1"></span><span class="path2"></span
              ></i>
              <span class="fs-16">Settings</span>
            </a>
          </div>
          <div class="col-6">
            <a
              class="
                waves-effect waves-light
                btn btn-app btn btn-primary-light btn-flat
                mx-0
                mb-20
                no-shadow
                py-35
                h-auto
                d-block
              "
              href="ecommerce_orders.html"
            >
              <i class="icon-Euro fs-36"
                ><span class="path1"></span><span class="path2"></span
              ></i>
              <span class="fs-18">Orders</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
