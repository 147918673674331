<div class="bg-white rounded10 shadow-lg hide-overflow-limit" style="position: relative;">
  <mat-progress-bar class="progress-bar-top" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <div class="content-top-agile p-20">
    <img
      src="{{user?.profileImageId?.imageUrl ? user?.profileImageId?.imageUrl : '/assets/images/icons/profile.png'}}"
      alt="User Image"
      style="height: 100px;width: 100px;"
      class="rounded-circle"
    />
    <h3 class="mb-0">{{ user.name ? 'Hi '+(user.name | titlecase) : 'Hi Nobie'}}</h3>
    <p>{{user.email}}</p>
  </div>
  <div class="pt-0 pb-40 pe-40 ps-40">
    <form [formGroup]="lockForm">
      <app-alert></app-alert>
      <div class="form-group">
        <div class="input-group">
          <span class="input-group-text bg-transparent"
            ><i class="ti-lock"></i
          ></span>
          <input  type="password" formControlName="password" class="form-control ps-15 bg-transparent" placeholder="Enter Your Password"/>
        </div>
        <div *ngIf="isControlHasError('password')" class="form-control-feedback text-danger">
          <small *ngIf="isControlHasError('password','required')">
            Password is required
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <button type="submit" (click)="onSubmit()" class="btn btn-primary mt-10">UNLOCK</button>
        </div>
        <!-- /.col -->
      </div>
    </form>

    <div class="text-center">
      <p class="mt-20">- OR -</p>
      <p class="mb-5">Enter your password to retrieve your session</p>
    </div>

    <div class="text-center">
      <p class="mt-15 mb-0">
        Or <a href="javascript:void(0)" (click)="signInDiff()" class="text-warning"><b>Sign In</b></a> as
        a Different User
      </p>
    </div>
  </div>
</div>
