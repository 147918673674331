<!-- no internet screen -->
<div class="theme-primary">
  <div class="app-no-internet-screen">
    <img src="assets/images/no-wifi.png" alt="Logo">
    <span class="text-center">You are not connected to the internet. <br> Please re-check your connection and try again.</span>
    <div class="text-center">
      <button class="btn btn-primary" href="javascript:void(0)" (click)="reload()">Check Connection</button>
    </div>
  </div>
</div>
