// Angular
import { Injectable } from '@angular/core';
// RxJS
import { Subject, BehaviorSubject } from 'rxjs';
// Object path
import * as objectPath from 'object-path';

@Injectable()
export class MenuConfigService {

	menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	// Private properties
	private menuConfig: any;

	/**
	 * Service Constructor
	 */
	constructor() {}

	/**
	 * Returns the menuConfig
	 */
	getMenus() {
		return this.menuConfig;
	}

	/**
	 * Load config
	 *
	 * @param config: any
	 */
	loadConfigs(config: any) {
		this.menuConfig = config;
		const menuItems: any[] = objectPath.get(this.menuConfig, 'aside.items');
		//console.log(menuItems);
		this.menuList$.next(menuItems);
	}
}
