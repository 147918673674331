<div class="bg-white rounded10 shadow-lg hide-overflow-limit" style="position: relative;">
  <mat-progress-bar class="progress-bar-top" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <div class="content-top-agile p-20 pb-0">
    <h2 class="text-primary">Let's Get Started</h2>
    <p class="mb-0">Sign in to continue using Smart Track.</p>
  </div>
  <div class="p-40">
    <form [formGroup]="loginForm">
      <app-alert></app-alert>
      <div class="form-group">
        <div class="input-group">
          <span class="input-group-text bg-transparent"
            ><i class="ti-user"></i
          ></span>
          <input type="email" formControlName="email" class="form-control ps-15 bg-transparent" placeholder="Enter Your Email"/>
        </div>
        <div *ngIf="isControlHasError('email')"  class="form-control-feedback text-danger">
            <small *ngIf="isControlHasError('email','required')">
              Email is required
            </small>
            <small *ngIf="isControlHasError('email','pattern')">
              Email must be a valid email address
            </small>
          </div>
      </div>
      <div class="form-group">
        <div class="input-group mt-3">
          <span class="input-group-text bg-transparent"
            ><i class="ti-lock"></i
          ></span>
          <input type="password" formControlName="password" class="form-control ps-15 bg-transparent" placeholder="Enter Your Password"/>

        </div>
        <div *ngIf="isControlHasError('password')" class="form-control-feedback text-danger">
            <small *ngIf="isControlHasError('password','required')">
              Password is required
            </small>
          </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="checkbox">
            <input type="checkbox" id="basic_checkbox_1"  formControlName="remindMe"/>
            <label for="basic_checkbox_1">Remember Me</label>
          </div>
        </div>
        <!-- /.col -->
        <div class="col-6">
          <div class="fog-pwd text-end">
            <a href="javascript:void(0)" routerLink="/auth/forgot-password" class="hover-warning"
              ><i class="ion ion-locked"></i> Forgot password?</a
            ><br />
          </div>
        </div>
        <!-- /.col -->
        <div class="col-12 text-center">
          <button type="submit" (click)="onSubmit()" class="btn btn-primary mt-10">SIGN IN</button>
        </div>
        <!-- /.col -->
      </div>
    </form>
    <div class="text-center">
      <p class="mt-15 mb-0">
        Don't have an account?
        <a href="javascript:void(0)" routerLink="/auth/register" class="text-warning ms-5">Sign Up</a>
      </p>
    </div>
  </div>
</div>
