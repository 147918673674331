// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
// RxJS
import { Observable, of } from 'rxjs';
import { tap, map, mergeMap, catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
// Layout
// import { LayoutUtilsService, MessageType } from '../../../../core/_base/crud/';

import { environment } from '../../../../../environments/environment';

const API_URL = environment.apiUrl;

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {

  constructor(private router: Router,
    private http: HttpClient) { }

  authToken: string = '';

  isRefreshToken: boolean = false;

  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {


    if (request.url.includes('resetPassword')) {
      request = request.clone({});
    } else {
      this.authToken = localStorage.getItem(environment.authTokenKey);
      request = request.clone({
        setHeaders: {
          'token': `${this.authToken}`
        }
      });
    }

    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            if (event.status === 200) {
              if (!request.url.includes("refreshToken") && localStorage.getItem(environment.authTokenKey) && localStorage.getItem(environment.authTokenKey).length > 0) {
                if (!this.isRefreshToken) {
                  this.refreshToken();
                }
              }
            }
          }
        },
        error => {
          if (error.status === 401) {
            if (!request.url.includes('changePassword')) {
              localStorage.removeItem(environment.authTokenKey);
            }
            if (!request.url.includes('resetPassword') && !request.url.includes('login') && !request.url.includes('changePassword')) {
              this.router.navigate(['/auth/login'], { queryParams: { type: 'expired' } });
            }
          }
        }
      )
    );
  }

  refreshToken() {
    this.isRefreshToken = true;
    return this.http.get<any>(API_URL + '/refreshToken').subscribe(
      res => {
        if (res.response.code === 200) {
          this.isRefreshToken = false;
          localStorage.setItem(environment.authTokenKey, res.data.token);
          this.authToken = res.data.token;
          return null;
        }
      }
    );
  }
}
