<div class="main-body hold-transition theme-primary bg-img"
  style="background-image: url(assets/images/map-bg/map-bg-45.jpg);height: 100vh;">
  <div class="container h-p100">
    <div class="row align-items-center justify-content-md-center h-p100">
      <div class="col-12">
        <div class="row justify-content-center g-0">
          <div class="col-lg-5 col-md-5 col-12">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
