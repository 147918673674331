import { OnDestroy } from '@angular/core';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {

  showSignIn: number = 0;

  private subscriptions: Subscription[] = [];

  authToken: string = localStorage.getItem(environment.authTokenKey);

  constructor(private router: Router,
    private cdr: ChangeDetectorRef,
    private modulesService: NgxPermissionsService,) {

    this.subscriptions.push(this.router.events.subscribe((event: any) => {
      if (this.router.url.includes('/forgot-password')) {
        this.showSignIn = 1;
      } else if (this.router.url.includes('/lock')) {
        this.showSignIn = 2;
      } else {
        this.showSignIn = 0;
      }
      this.cdr.detectChanges();
    }));
  }

  ngOnInit(): void {
    if (this.authToken && this.authToken.length > 0) {
      this.router.navigateByUrl('/dashboard'); // Main page
    }
    this.modulesService.flushPermissions();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  login() {
    localStorage.removeItem(environment.userSession);
    this.router.navigateByUrl('/auth/login');
  }

}
