<!-- breadcrumb item -->
<div class="content-header">
  <div class="d-flex align-items-center">
    <div class="me-auto">
      <h4 class="page-title">{{ title || 'Dashboard' }}</h4>
      <div class="d-inline-block align-items-center">
        <nav>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/dashboard"><i class="fa fa-home"></i></a></li>
            <ng-container *ngFor="let item of breadcrumbs">
              <li class="breadcrumb-item" *ngIf="item.page">
                <a href="javascript: void(0);" [routerLink]="item.page">{{ item.title }}</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page" *ngIf="!item.page">{{ item.title }}
              </li>
            </ng-container>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
