<div class="main-body hold-transition theme-primary bg-img" style="background-image: url(assets/images/auth-bg/bg-4.jpg);height: 100vh;">
	<section class="error-page h-p100">
		<div class="container h-p100">
		  <div class="row h-p100 align-items-center justify-content-center text-center">
			  <div class="col-lg-7 col-md-10 col-12">
				  <div class="rounded10">
					  <img src="assets/images/auth-bg/404.jpg" class="max-w-200" alt="" />
            <h1>{{code}}</h1>
					  <h2>{{title}}</h2>
					  <p>{{subtitle}}</p>
					  <div class="my-30"><a href="javascript:void(0)" routerLink="{{return.returnUrl}}" class="btn btn-danger">{{return.label}}</a></div>
				  </div>
			  </div>
		  </div>
		</div>
	</section>
</div>
