// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  envName: 'dev',
  authTokenKey: 'autht9899assetTracko',
  userSession: 'users9899assetTracko',
  googleApiKey: 'AIzaSyBYfr5AnsdHtsYSAOWo5d5Lv7USfSvOmx8',
  //razorPayKey: 'rzp_test_ROP5kg4BrRfTUq',
  //razorPayKey: 'rzp_live_hpWi2dYfdsVYO0',
  //googleApiKey: 'AIzaSyBYfr5AnsdHtsYSAOWo5d5Lv7USfSvOmx8',
  apiUrl: 'https://smarttrackbe.woztechs.com/dev',
  //apiUrl: 'https://smarttrackbe.woztechs.com/dev',
  //apiUrl: 'https://smarttrackbe.woztechs.com/dev',

  firebaseConfig: {
    apiKey: "AIzaSyAp0eAYAoY_D0ag157z8dCQfwP2PEo9cRA",
    authDomain: "dots-taxi-dev.firebaseapp.com",
    databaseURL: "https://dots-taxi-dev.firebaseio.com/",
    projectId: "dots-taxi-dev",
    storageBucket: "dots-taxi-dev.appspot.com",
    messagingSenderId: "1026074205887",
    appId: "1:1026074205887:web:16df26d9fda3e17560700a"
  }

  // firebaseConfig: {
  //   apiKey: "AIzaSyBJGOZwu-2r2T4pXY5U0-YsKoolQ5lXB1g",
  //   authDomain: "dots-taxi-test.firebaseapp.com",
  //   databaseURL: "https://dots-taxi-test.firebaseio.com",
  //   projectId: "dots-taxi-test",
  //   storageBucket: "dots-taxi-test.appspot.com",
  //   messagingSenderId: "1027212189133",
  //   appId: "1:1027212189133:web:0ad5767e58db2eebed3884"
  // }

  // firebaseConfig: {
  //   apiKey: "AIzaSyBfqOmvbXEVyqdZHchTxeOFwKeIeKUskqM",
  //   authDomain: "dots-taxi-stage.firebaseapp.com",
  //   databaseURL: "https://dots-taxi-stage-default-rtdb.firebaseio.com",
  //   projectId: "dots-taxi-stage",
  //   storageBucket: "dots-taxi-stage.appspot.com",
  //   messagingSenderId: "1066933439723",
  //   appId: "1:1066933439723:web:9281d09d59364fd95cc2ae",
  //   measurementId: "G-Z1G7XD61R3"
  // }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
