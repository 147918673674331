// Angular
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
// RxJS
import { Subscription } from 'rxjs';
// Layout
import { SubheaderService } from '../../../../core/_base/layout';
import { Breadcrumb } from '../../../../core/_base/layout/services/subheader.service';

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html'
})
export class SubheaderComponent implements OnInit, OnDestroy, AfterViewInit {

  title: string = '';
	breadcrumbs: Breadcrumb[] = [];

	// Private properties
	private subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param subheaderService: SubheaderService
	 */
	constructor(public subheaderService: SubheaderService,
				private cdr: ChangeDetectorRef) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
	}

	/**
	 * After view init
	 */
	ngAfterViewInit(): void {
		this.subscriptions.push(this.subheaderService.title$.subscribe(title => {
			// breadcrumbs title sometimes can be undefined
			//console.log(title);
			if (title) {
				Promise.resolve(null).then(() => {
					this.title = title;
					this.cdr.detectChanges();
				});
			}
		}));

		this.subscriptions.push(this.subheaderService.breadcrumbs$.subscribe(bc => {
			//console.log(bc);
			Promise.resolve(null).then(() => {
				this.breadcrumbs = bc;
				this.cdr.markForCheck();
			});
		}));
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

}
