<footer class="main-footer">
  <div class="pull-right d-none d-sm-inline-block">
    <ul
      class="
        nav nav-primary nav-dotted nav-dot-separated
        justify-content-center justify-content-md-end
      "
    >
      <!-- <li class="nav-item">
        Designed & Deveoped By <a style="margin-left: 10px;" href="https://woztechs.com/" target="_blank"> Woz technologies Pvt. Ltd.</a>
      </li> -->
    </ul>
  </div>
  &copy; {{year}}
  <a href="javascript:void(0)">Smart Track - An Asset Tracking Company</a>. All
  Rights Reserved.
</footer>
