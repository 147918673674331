export class Module {
    _id: number;
    title: string;
    module: string;
    operations: {
        view: boolean,
        edit: boolean,
        delete: boolean
    };
    __v: number;

    clear(): void {
        this._id = undefined;
        this.title = '';
        this.module = '';
        this.operations = {
            view: false,
            edit: false,
            delete: false
        };
        this.__v = 0;
	}
}
