// Angular
import { Component, Inject, OnInit, ChangeDetectorRef, NgZone, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Maps
import { MapsAPILoader, MouseEvent } from '@agm/core';

import { fromEvent, merge, Subscription, of, Observable, BehaviorSubject } from 'rxjs';

// Services
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../core/_base/crud/';

@Component({
  selector: 'app-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.scss']
})
export class LocationPickerComponent implements OnInit {

  zoom: number = 15;
  private geoCoder;
  mapType: any = "roadmap";
  private subscriptions: Subscription[] = [];

  location: any = {
    lat: 13.0227940488208,
    long: 77.71213899645313,
    address: '',
    additionalInfo: ''
  };

  styles = [
    {
      "featureType": "landscape.man_made",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#eaeaea"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#fed89e"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#eebc79"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#fde7c4"
        }
      ]
    }
  ];

  @ViewChild('search', { static: false }) searchElementRef: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<LocationPickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private layoutService: LayoutUtilsService
  ) { }

  ngOnInit() {
    this.location = JSON.parse(JSON.stringify(Object.assign({}, this.location, this.data.data)));
    console.log(this.location);

    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      if (this.location?.id || (this.location?.lat && this.location?.long)) {
        this.getAddress(this.location.lat, this.location.long);
      } else {
        this.currentLocation();
      }

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, { componentRestrictions: { country: 'IN' } });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.location.lat = place.geometry.location.lat();
          this.location.long = place.geometry.location.lng();
          this.getAddress(this.location.lat, this.location.long);
        });
      });
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    this.dialogRef.close(this.location);
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.location = {
      lat: $event.coords.lat,
      long: $event.coords.lng,
      address: this.location.address,
      additionalInfo: this.location.additionalInfo
    }
    this.getAddress(this.location.lat, this.location.long);
  }

  currentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.location.lat = position.coords.latitude;
        this.location.long = position.coords.longitude;
        this.getAddress(this.location.lat, this.location.long);
        this.zoom = 13;
      },
        error => {
          this.layoutService.showActionNotification(MessageType.warning, "Location Access Required", "Location access is not enabled on your browser.");
        });
    } else {
      this.layoutService.showActionNotification(MessageType.danger, "Unsupported!", "Geolocation is not supported by this browser.");
    }
  }


  getAddress(latitude, longitude) {
    this.geoCoder.accessibilityLanguage = "en-US";
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          console.log(results[0]);
          this.location.address = results[0].formatted_address;
        } else {
          window.alert('');
          this.layoutService.showActionNotification(MessageType.warning, "No results found", "No location found");
        }
      } else if (status === 'OVER_QUERY_LIMIT') {
        this.layoutService.showActionNotification(MessageType.warning, 'Limit Exceeds!', 'Request exceeds limit. Please try again after some time.');
      } else {
        this.layoutService.showActionNotification(MessageType.danger, 'Geocoder Error!', 'Geocoder failed due to: ' + status);
      }
    });
  }
}
