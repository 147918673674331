export class QueryParamsModel {
	// fields
	filter: any;
	sortOrder: string; // asc || desc
	sortField: string;
	pageNumber: number;
	pageSize: number;
	searchText: string;

	// constructor overrides
	constructor(_filter: any,
	           _sortOrder: string = 'asc',
	           _sortField: string = '_id',
	           _pageNumber: number = 1,
	           _pageSize: number = 10,
	           _searchText: string = '') {
		this.filter = _filter;
		this.sortOrder = _sortOrder;
		this.sortField = _sortField;
		this.pageNumber = _pageNumber;
		this.pageSize = _pageSize;
		this.searchText = _searchText;
	}
}
