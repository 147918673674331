import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

// google maps
import { AgmCoreModule } from "@agm/core";

// Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { ErrorPageComponent } from './general/error-page/error-page.component';
import { SplashScreenComponent } from './general/splash-screen/splash-screen.component';
import { NoInternetComponent } from './general/no-internet/no-internet.component';
import { AlertComponent } from './crud/alert/alert.component';
import { ConfirmationEntityComponent } from './crud/confirmation-entity/confirmation-entity.component';
import { SubheaderComponent } from './layout/subheader/subheader.component';
import { ProfileComponent } from './layout/profile/profile.component';
import { NotificationsComponent } from './layout/notifications/notifications.component';
import { QuickActionsComponent } from './layout/quick-actions/quick-actions.component';
import { QuickPanelComponent } from './layout/quick-panel/quick-panel.component';
import { QuickShopComponent } from './layout/quick-shop/quick-shop.component';
import { DropzoneUploadComponent } from './crud/dropzone-upload/dropzone-upload.component';
import { LocationPickerComponent } from './crud/location-picker/location-picker.component';
import { CardLoadingComponent } from './general/card-loading/card-loading.component';

// const config: DropzoneConfigInterface = {
//   // Change this to your upload POST address:
//   url: "http://13.127.255.159:6000/fileUpload",
//   maxFilesize: 1,
//   // acceptedFiles: 'image/*',
//   createImageThumbnails: true
// };

// Environment Variables
import { environment } from '../../../environments/environment';



@NgModule({
  declarations: [
  	ErrorPageComponent,
  	SplashScreenComponent,
  	AlertComponent,
  	ConfirmationEntityComponent,
  	SubheaderComponent,
  	ProfileComponent,
  	NotificationsComponent,
  	DropzoneUploadComponent,
  	LocationPickerComponent,
    NoInternetComponent,
    CardLoadingComponent,
    QuickActionsComponent,
    QuickPanelComponent,
    QuickShopComponent
  ],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    NgbDropdownModule,
    ClickOutsideModule,
    RouterModule,
    MatDialogModule,
    NgxDropzoneModule,
    MatTooltipModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    MatProgressBarModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      libraries: ['geometry', 'places', 'drawing'],
      language: 'en',
    }),
  ],
  providers: [
    // {
    //   provide: DROPZONE_CONFIG,
    //   useValue: config,
    // }
  ],
  exports: [
    SplashScreenComponent,
    NoInternetComponent,
    AlertComponent,
    SubheaderComponent,
    ProfileComponent,
    NotificationsComponent,
    QuickActionsComponent,
    QuickPanelComponent,
    QuickShopComponent,
    ConfirmationEntityComponent,
    DropzoneUploadComponent,
    Ng2SearchPipeModule,
    CardLoadingComponent,
  ],
  entryComponents: [
    ConfirmationEntityComponent,
    DropzoneUploadComponent,
    LocationPickerComponent
  ]
})
export class PartialsModule { }
