// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Partials
import { PartialsModule } from '../../partials/partials.module';
// CRUD
import { InterceptService } from '../../../core/_base/crud/';
import { MatProgressBarModule } from '@angular/material/progress-bar';
// Auth
import { AuthEffects, AuthGuard, authReducer, AuthService } from '../../../core/auth';

import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LockScreenComponent } from './lock-screen/lock-screen.component';
import { RegisterComponent } from './register/register.component';

const routes: Routes = [
	{
		path: '',
		component: AuthComponent,
		children: [
			{
				path: '',
				redirectTo: 'login',
				pathMatch: 'full'
			},
			{
				path: 'login',
				component: LoginComponent,
				data: {returnUrl: window.location.pathname}
			},
			{
				path: 'lock',
				component: LockScreenComponent,
				data: {returnUrl: window.location.pathname}
			},
			{
				path: 'forgot-password',
				component: ForgotPasswordComponent,
			},
			{
				path: 'reset-password',
				component: ResetPasswordComponent
			},
			{
				path: 'change-password',
				component: ResetPasswordComponent
			},
      {
        path: 'register',
        component: RegisterComponent
      },
		]
	}
];

@NgModule({
	declarations: [AuthComponent, LoginComponent, ForgotPasswordComponent, ResetPasswordComponent, LockScreenComponent, RegisterComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(routes),
		StoreModule.forFeature('auth', authReducer),
		EffectsModule.forFeature([AuthEffects]),
		PartialsModule,
    MatProgressBarModule
	],
  	providers: [
		InterceptService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptService,
			multi: true
		},
	],
})
export class AuthModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: AuthModule,
			providers: [
				AuthService,
				AuthGuard
			]
		};
	}
}
