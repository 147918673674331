<div
  class="main-body hold-transition light-skin sidebar-mini theme-primary fixed"
>
  <div class="wrapper">
    <app-topbar></app-topbar>

    <app-sidebar></app-sidebar>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <div class="container-full">
        <app-subheader></app-subheader>
        <!-- Main content -->
        <section class="content">
          <router-outlet></router-outlet>
        </section>
        <!-- /.content -->
      </div>
    </div>
    <!-- /.content-wrapper -->

    <app-footer></app-footer>
    <!-- Side panel -->
    <!-- quick_actions_toggle -->
    <app-quick-actions></app-quick-actions>
    <!-- /quick_actions_toggle -->

    <!-- quick_panel_toggle -->
    <app-quick-panel></app-quick-panel>
    <!-- /quick_panel_toggle -->

    <!-- quick_shop_toggle -->
    <app-quick-shop></app-quick-shop>
    <!-- /quick_shop_toggle -->
  </div>
  <!-- ./wrapper -->
</div>
