import { Module } from './module.model';

export class Role {
    _id: undefined;
    isDelete: boolean;
    role: string;
    createdById: string;
    modules: Module[];
    readOnly: boolean;
    isCore: boolean;
    __v: number;

    clear(): void {
        this._id = undefined;
        this.isDelete = false;
        this.role = '';
        this.createdById = '';
        this.modules = [];
        this.readOnly = false;
        this.isCore = false;
        this.__v = 0;
    }
}
