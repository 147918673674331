import { Role } from './role.model';

export class User {
  _id: string;
  mobileNumber: {
    number: string,
    countryCode: string
  };
  name: string;
  email: string;
  verified: boolean;
  address: string;
  dateOfJoining: string;
  role: string;
  roleDetails: Role;
  activeStatus: boolean;
  hasDashboard: boolean;
  status: {
    isSuspend: boolean,
    reason: string
  };
  employeeID: string;
  firstTimeLogin: boolean;
  isSuperAdmin: boolean;
  profileImageId: {
    imageUrl: string,
    fileUploadId: string
  };
  organizationId: string[];

  clear(): void {
    this._id = undefined;
    this.mobileNumber = {
      number: undefined,
      countryCode: "971"
    };
    this.name = '';
    this.email = '';
    this.verified = false;
    this.address = '';
    this.dateOfJoining = '';
    this.role = undefined;
    this.roleDetails = new Role();
    this.roleDetails.clear();
    this.activeStatus = false;
    this.hasDashboard = true;
    this.status = {
      isSuspend: false,
      reason: ''
    };
    this.employeeID = undefined;
    this.firstTimeLogin = true;
    this.isSuperAdmin = false;
    this.profileImageId = {
      imageUrl: undefined,
      fileUploadId: undefined
    };
    this.organizationId = [];
  }
}
