export class MenuConfig {
	public defaults: any = {
		aside: {
			self: {},
			items: [
				// {
				// 	section: 'Dashboards',
        //   //permissions: ['operationsDashboard', 'operationsTeamManagement', 'driverManagement', 'kycManagement', 'subscriptionManagement', 'subscribersManagement']
				// },
				{
					title: 'Dashboards',
          icon: 'icon-Layout-4-blocks',
          page: '/dashboard',
				},
				{
					section: 'Manage',
          permissions: ['userManagement', 'rolesManagement', 'workflowManagement', 'deviceManagement', 'assetManagement', 'projectManagement', 'locationManagement']
				},
				{
					title: 'Users',
          icon: 'icon-Add-user',
          permissions: ['userManagement', 'roleManagement', 'workflowManagement'],
					submenu: [
						{
							title: 'Users',
							page: '/users/members',
              permission: 'userManagement'
						},
						{
							title: 'Roles',
							page: '/users/roles',
              permission: 'roleManagement'
						},
						{
							title: 'Work Flow',
							page: '/users/work-flow',
              permission: 'workflowManagement'
						}
					]
				},
				{
					title: 'Devices',
          icon: 'icon-Hard-drive',
					page: '/devices',
					permission: 'deviceManagement'
				},
				{
					title: 'Assets',
          icon: 'icon-Home-heart',
					page: '/assets',
					permission: 'assetManagement'
				},
				{
          title: 'Projects',
          icon: 'icon-Penruller',
          permissions: ['projectManagement', 'locationManagement'],
          submenu: [
            {
              title: 'All Projects',
              page: '/projects/projects',
              permission: 'projectManagement'
            },
            {
              title: 'Locations',
              page: '/projects/locations',
              permission: 'locationManagement'
            }
          ]
				},
				{
					section: 'Reports',
          permissions: ['reportManagement']
				},
				{
					title: 'Report',
          icon: 'icon-Chart-pie',
					page: '/reports',
					permission: 'reportManagement'
				},
				{
					section: 'Logs',
          permissions: ['logManagement']
				},
				{
					title: 'System Logs',
          icon: 'icon-Lock-overturning',
					page: '/logs',
          permission: 'logManagement'
				},
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
