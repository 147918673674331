// NGRX
import { Action } from '@ngrx/store';
// Models
import { Module } from '../_models/module.model';

export enum ModuleActionTypes {
    AllModulesRequested = '[Init] All Modules Requested',
    AllModulesLoaded = '[Init] All Modules Loaded'
}

export class AllModulesRequested implements Action {
    readonly type = ModuleActionTypes.AllModulesRequested;
}

export class AllModulesLoaded implements Action {
    readonly type = ModuleActionTypes.AllModulesLoaded;
    constructor(public payload: { modules: Module[] }) { }
}

export type ModuleActions = AllModulesRequested | AllModulesLoaded;
