import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Module } from '../_models/module.model';
import { Role } from '../_models/role.model';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

const API_URL = environment.apiUrl;

@Injectable()
export class AuthService {
  constructor(private http: HttpClient) { }

  // Login API
  login(data: any): Observable<any> {
    return this.http.post<any>(API_URL + '/login', data);
  }

  // Forgot Password API
  forgotPassword(data: any): Observable<any> {
    return this.http.post<any>(API_URL + '/forgotPassword', data);
  }

  // Confirm Password API
  resetPassword(newPassword: string, accessToken: string): Observable<any> {
    var data = {
      password: newPassword
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'token': accessToken
      })
    };
    return this.http.post<any>(API_URL + '/resetPassword', data, httpOptions);
  }

  // Get user details by token API
  getUserByToken(): Observable<any> {
    return this.http.get<any>(API_URL + '/member');
  }

  // Change Password API
  changePassword(data: any): Observable<any> {
    return this.http.put<any>(API_URL + '/changePassword', data);
  }

  // Update Profle
  updateProfile(data: any): Observable<any> {
    //console.log(data);
    return this.http.put<any>(API_URL + '/member', data);
  }

  // Module
  getAllModules(): Observable<Module[]> {
    return this.http.get<Module[]>(API_URL + '/modules').pipe(
      mergeMap((res: any) => {
        //console.log(res);
        if (res.response.code === 200) {
          const result = res.data;
          return of(result);
        } else {
          const result = [];
          return of(result);
        }
      })
    );
  }

  // Roles
  getAllRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(API_URL + '/role/all?isDelete=false').pipe(
      mergeMap((res: any) => {
        //console.log(res);
        if (res.response.code === 200) {
          const result = res.data.list;
          return of(result);
        } else {
          const result = [];
          return of(result);
        }
      })
    );
  }

  // CREATE =>  POST: add a new role to the server
  addRole(role: Role): Observable<Role> {
    return this.http.post<Role>(API_URL + '/role', role);
  }

  // UPDATE => PUT: update the role on the server
  updateRole(role: Role): Observable<any> {
    return this.http.put(API_URL + '/role', role);
  }

  // DELETE => delete the role from the server
  deleteRole(role: Role): Observable<Role> {
    return this.http.delete<Role>(API_URL + '/role?_id=' + role._id + '&isDelete=' + role.isDelete);
  }

  // Find role filter/sort api
  findRoles(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(API_URL + '/role/all?sortField=' + queryParams.sortField + '&sortOrder=' + queryParams.sortOrder + '&pageNumber=' + queryParams.pageNumber + '&pageSize=' + queryParams.pageSize + '&searchText=' + queryParams.searchText).pipe(
      mergeMap((res: any) => {
        //console.log(res);
        if (res.response.code === 200) {
          const result = {
            items: res.data.list,
            totalCount: res.data.totalCount
          };
          return of(result);
        } else {
          const result = {
            items: [],
            totalCount: 0
          };
          return of(result);
        }
      })
    );
  }
}
