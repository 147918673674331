<aside class="main-sidebar">
  <!-- sidebar-->
  <section class="sidebar position-relative">
    <div
      class="
        d-flex
        align-items-center
        logo-box
        justify-content-start
        d-md-block d-none
      "
    >
      <!-- Logo -->
      <a href="javascript:void(0)" class="logo">
        <!-- logo-->
        <div class="logo-mini">
          <span class="light-logo"
            ><img src="assets/images/logo-letter.png" alt="logo"
          /></span>
        </div>
        <div class="logo-lg">
          <span class="light-logo fs-24 fw-700"
            >SMART<span class="text-primary"> Track</span></span
          >
        </div>
      </a>
    </div>
    <app-profile></app-profile>

    <div class="multinav">
      <perfect-scrollbar style="height: 97%">
        <div class="multinav-scroll" style="height: 97%">
          <!-- sidebar menu-->
          <ul class="sidebar-menu" data-widget="tree">
            <ng-container [ngTemplateOutlet]="menuListTemplate"></ng-container>
          </ul>

          <div class="sidebar-widgets">
            <div class="mx-25 mb-30 pb-20 side-bx bg-lightest rounded20">
              <div class="text-center">
                <img
                  src="assets/images/asset-tracking.png"
                  class="sideimg p-5"
                  alt=""
                />
                <h4 class="title-bx text-primary">Monitor Your Assets</h4>
                <a href="javascript:void(0)" class="py-10 fs-14 mb-0 text-primary">
                  Hassle Free Tracking <i class="mdi mdi-arrow-right"></i>
                </a>
              </div>
            </div>
            <div class="copyright text-center m-25">
              <p>
                <strong class="d-block">Smart Track Dashboard</strong> © {{year}}
                All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </section>
</aside>

<!--- Menu Starts -->
<ng-template #menuListTemplate>
  <ng-container *ngFor="let child of menuConfigService.menuList$ | async">
    <ng-container *ngIf="child.section" [ngTemplateOutlet]="menuItemSectionTemplate"
      [ngTemplateOutletContext]="{ item: child }"></ng-container>
    <ng-container *ngIf="child.title" [ngTemplateOutlet]="menuItemTemplate"
      [ngTemplateOutletContext]="{ item: child }"></ng-container>
  </ng-container>
</ng-template>
<!--- Menu Ends -->

<!--- Menu Section Starts -->
<ng-template #menuItemSectionTemplate let-item="item" let-parentItem="parentItem">
  <ng-container *ngIf="!item.permissions">
    <li class="header">{{ item.section }}</li>
  </ng-container>
  <ng-template *ngIf="item.permissions" [ngxPermissionsOnly]="item.permissions">
    <li class="header">{{ item.section }}</li>
  </ng-template>
</ng-template>
<!--- Menu Section Ends -->

<!--- Menu Items Starts -->
<ng-template #menuItemTemplate let-item="item" let-parentItem="parentItem">
  <ng-container *ngIf="!item.permission && !item.permissions" [ngTemplateOutlet]="menuItemInnerTemplate" [ngTemplateOutletContext]="{ item: item, parentItem: parentItem  }"></ng-container>
  <ng-template *ngIf="item.permission" ngxPermissionsOnly="{{ item.permission }}">
    <ng-container [ngTemplateOutlet]="menuItemInnerTemplate" [ngTemplateOutletContext]="{ item: item, parentItem: parentItem  }"></ng-container>
  </ng-template>
  <ng-template *ngIf="item.permissions" [ngxPermissionsOnly]="item.permissions">
    <ng-container [ngTemplateOutlet]="menuItemInnerTemplate" [ngTemplateOutletContext]="{ item: item, parentItem: parentItem  }"></ng-container>
  </ng-template>
</ng-template>
<!--- Menu Items Ends -->

<!--- Menu Items inner Starts -->
<ng-template #menuItemInnerTemplate let-item="item" let-parentItem="parentItem">
    <!-- if menu item hasn't submenu -->
    <li *ngIf="!item.submenu">
      <a [routerLink]="item.page">
        <i class="{{ item.icon ? item.icon : 'icon-Commit' }}"
          ><span class="path1"></span><span class="path2"></span
        ></i>
        <span>{{ item.title }}</span>
      </a>
    </li>
    <!-- if menu item has sumnenu child  -->
    <li class="treeview" *ngIf="item.submenu">
      <a href="javascript:void(0);">
        <i class="{{ item.icon ? item.icon : 'icon-Commit' }}">
          <span class="path1"></span><span class="path2"></span>
        </i>
        <span>{{ item.title }}</span>
        <span class="pull-right-container">
          <i class="fa fa-angle-right pull-right"></i>
        </span>
      </a>
      <!-- if menu item has submenu child then recursively call new menu item component -->
      <ul *ngIf="item.submenu" class="treeview-menu">
        <ng-container *ngFor="let submenu of item.submenu">
          <ng-container *ngIf="!submenu.permission && !submenu.permissions" [ngTemplateOutlet]="menuItemInnerTemplate" [ngTemplateOutletContext]="{ item: submenu, parentItem: item  }"></ng-container>
          <ng-template *ngIf="submenu.permission" ngxPermissionsOnly="{{ submenu.permission }}">
            <ng-container [ngTemplateOutlet]="menuItemInnerTemplate" [ngTemplateOutletContext]="{ item: submenu, parentItem: item  }"></ng-container>
          </ng-template>
          <ng-template *ngIf="submenu.permissions" [ngxPermissionsOnly]="submenu.permissions">
            <ng-container [ngTemplateOutlet]="menuItemInnerTemplate" [ngTemplateOutletContext]="{ item: submenu, parentItem: item  }"></ng-container>
          </ng-template>
        </ng-container>
        <!-- <li *ngFor="let submenu of item.submenu">
          <ng-container *ngIf="!submenu.permission && !submenu.permissions">
            <a [routerLink]="submenu.page" routerLinkActive="active"><i class="icon-Commit"
                ><span class="path1"></span
                ><span class="path2"></span></i
              >{{ submenu.title }}</a>
          </ng-container>
          <ng-template *ngIf="submenu.permission" ngxPermissionsOnly="{{ submenu.permission }}">
            <a [routerLink]="submenu.page" routerLinkActive="active"><i class="icon-Commit"
                ><span class="path1"></span
                ><span class="path2"></span></i
              >{{ submenu.title }}</a>
          </ng-template>
          <ng-template *ngIf="submenu.permissions" [ngxPermissionsOnly]="submenu.permissions">
            <a [routerLink]="submenu.page" routerLinkActive="active"><i class="icon-Commit"
                ><span class="path1"></span
                ><span class="path2"></span></i
              >{{ submenu.title }}</a>
          </ng-template>
        </li> -->
      </ul>
    </li>
</ng-template>
<!--- Menu Items inner Ends -->
