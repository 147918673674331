<div class="modal modal-right fade" id="quick_shop_toggle" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div
              class="
                px-15
                d-flex
                w-p100
                align-items-center
                justify-content-between
              "
            >
              <h4 class="m-0">Shopping Cart</h4>
              <a
                href="#"
                class="btn btn-icon btn-danger-light btn-sm no-shadow"
                data-bs-dismiss="modal"
              >
                <span class="fa fa-close"></span>
              </a>
            </div>
          </div>
          <div class="modal-body px-30 pb-30 bg-white slim-scroll4">
            <div
              class="d-flex align-items-center justify-content-between pb-15"
            >
              <div class="d-flex flex-column me-2">
                <a href="#" class="fw-600 fs-18 text-hover-primary"
                  >Product Name</a
                >
                <span class="text-muted">When an unknown printer</span>
                <div class="d-flex align-items-center mt-2">
                  <span class="fw-600 me-5 fs-18">$ 125</span>
                  <span class="text-muted me-5">for</span>
                  <span class="fw-600 me-2 fs-18">4</span>
                  <a
                    href="#"
                    class="btn btn-sm btn-success-light btn-icon me-2"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a href="#" class="btn btn-sm btn-success-light btn-icon">
                    <i class="fa fa-plus"></i>
                  </a>
                </div>
              </div>
              <!-- <a href="#" class="flex-shrink-0">
                <img
                  src="assets/images/product/product-1.png"
                  class="avatar h-100 w-100"
                  alt=""
                />
              </a> -->
            </div>
            <div class="dropdown-divider"></div>
            <div
              class="d-flex align-items-center justify-content-between py-15"
            >
              <div class="d-flex flex-column me-2">
                <a href="#" class="fw-600 fs-18 text-hover-primary"
                  >Product Name</a
                >
                <span class="text-muted">When an unknown printer</span>
                <div class="d-flex align-items-center mt-2">
                  <span class="fw-600 me-5 fs-18">$ 125</span>
                  <span class="text-muted me-5">for</span>
                  <span class="fw-600 me-2 fs-18">4</span>
                  <a
                    href="#"
                    class="btn btn-sm btn-success-light btn-icon me-2"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a href="#" class="btn btn-sm btn-success-light btn-icon">
                    <i class="fa fa-plus"></i>
                  </a>
                </div>
              </div>
              <!-- <a href="#" class="flex-shrink-0">
                <img
                  src="assets/images/product/product-2.png"
                  class="avatar h-100 w-100"
                  alt=""
                />
              </a> -->
            </div>
            <div class="dropdown-divider"></div>
            <div
              class="d-flex align-items-center justify-content-between py-15"
            >
              <div class="d-flex flex-column me-2">
                <a href="#" class="fw-600 fs-18 text-hover-primary"
                  >Product Name</a
                >
                <span class="text-muted">When an unknown printer</span>
                <div class="d-flex align-items-center mt-2">
                  <span class="fw-600 me-5 fs-18">$ 125</span>
                  <span class="text-muted me-5">for</span>
                  <span class="fw-600 me-2 fs-18">4</span>
                  <a
                    href="#"
                    class="btn btn-sm btn-success-light btn-icon me-2"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a href="#" class="btn btn-sm btn-success-light btn-icon">
                    <i class="fa fa-plus"></i>
                  </a>
                </div>
              </div>
              <!-- <a href="#" class="flex-shrink-0">
                <img
                  src="assets/images/product/product-3.png"
                  class="avatar h-100 w-100"
                  alt=""
                />
              </a> -->
            </div>
            <div class="dropdown-divider"></div>
            <div
              class="d-flex align-items-center justify-content-between py-15"
            >
              <div class="d-flex flex-column me-2">
                <a href="#" class="fw-600 fs-18 text-hover-primary"
                  >Product Name</a
                >
                <span class="text-muted">When an unknown printer</span>
                <div class="d-flex align-items-center mt-2">
                  <span class="fw-600 me-5 fs-18">$ 125</span>
                  <span class="text-muted me-5">for</span>
                  <span class="fw-600 me-2 fs-18">4</span>
                  <a
                    href="#"
                    class="btn btn-sm btn-success-light btn-icon me-2"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a href="#" class="btn btn-sm btn-success-light btn-icon">
                    <i class="fa fa-plus"></i>
                  </a>
                </div>
              </div>
              <!-- <a href="#" class="flex-shrink-0">
                <img
                  src="assets/images/product/product-4.png"
                  class="avatar h-100 w-100"
                  alt=""
                />
              </a> -->
            </div>
            <div class="dropdown-divider"></div>
            <div
              class="d-flex align-items-center justify-content-between py-15"
            >
              <div class="d-flex flex-column me-2">
                <a href="#" class="fw-600 fs-18 text-hover-primary"
                  >Product Name</a
                >
                <span class="text-muted">When an unknown printer</span>
                <div class="d-flex align-items-center mt-2">
                  <span class="fw-600 me-5 fs-18">$ 125</span>
                  <span class="text-muted me-5">for</span>
                  <span class="fw-600 me-2 fs-18">4</span>
                  <a
                    href="#"
                    class="btn btn-sm btn-success-light btn-icon me-2"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a href="#" class="btn btn-sm btn-success-light btn-icon">
                    <i class="fa fa-plus"></i>
                  </a>
                </div>
              </div>
              <!-- <a href="#" class="flex-shrink-0">
                <img
                  src="assets/images/product/product-5.png"
                  class="avatar h-100 w-100"
                  alt=""
                />
              </a> -->
            </div>
            <div class="dropdown-divider"></div>
            <div
              class="d-flex align-items-center justify-content-between py-15"
            >
              <div class="d-flex flex-column me-2">
                <a href="#" class="fw-600 fs-18 text-hover-primary"
                  >Product Name</a
                >
                <span class="text-muted">When an unknown printer</span>
                <div class="d-flex align-items-center mt-2">
                  <span class="fw-600 me-5 fs-18">$ 125</span>
                  <span class="text-muted me-5">for</span>
                  <span class="fw-600 me-2 fs-18">4</span>
                  <a
                    href="#"
                    class="btn btn-sm btn-success-light btn-icon me-2"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a href="#" class="btn btn-sm btn-success-light btn-icon">
                    <i class="fa fa-plus"></i>
                  </a>
                </div>
              </div>
              <!-- <a href="#" class="flex-shrink-0">
                <img
                  src="assets/images/product/product-6.png"
                  class="avatar h-100 w-100"
                  alt=""
                />
              </a> -->
            </div>
          </div>
          <div class="modal-footer modal-footer-uniform">
            <div
              class="d-flex align-items-center justify-content-between mb-10"
            >
              <span class="fw-600 text-muted fs-16 me-2">Total</span>
              <span class="fw-600 text-end">$1248.00</span>
            </div>
            <div
              class="d-flex align-items-center justify-content-between mb-15"
            >
              <span class="fw-600 text-muted fs-16 me-2">Sub total</span>
              <span class="fw-600 text-primary text-end">$4125.00</span>
            </div>
            <div class="text-end">
              <button type="button" class="btn btn-primary">Place Order</button>
            </div>
          </div>
        </div>
      </div>
    </div>
